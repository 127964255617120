import { computed, ref } from 'vue'
import Locale, { availableLang } from '@web/common/locale'
import { useStore } from '@web/store'

export default function () {
  const store = useStore()

  const lang = ref<string>(Locale.get())

  const selectedLang = computed(() => availableLang[lang.value])

  async function setLang (l) {
    lang.value = l
    await store.dispatch('user/changeLanguage', { locale: l })
    window.location.reload()
  }

  return {
    langList: availableLang,
    lang,
    selectedLang,
    setLang
  }
}
