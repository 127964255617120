
import { defineComponent, computed } from 'vue'

import Avatar from '@web/components.v2/Settings/Profile/Avatar.vue'
import Lang from '@web/components.v2/Settings/Lang.vue'

import { useStore } from '@web/store'
import { IS_WEB } from '@web/common/config'
import locale from '@web/common/locale'

export default defineComponent({
  name: 'SettingsSidebar',
  components: {
    Avatar,
    Lang
  },
  setup () {
    const store = useStore()

    const user = computed(() => store.getters['user/user'])

    const cookiesIsEnabled = computed(() => store.getters['cookie/isEnabled'])
    const isShowCookie = computed(() => cookiesIsEnabled.value)
    const isShowInstallments = computed(() => store.getters['installments/isLoaded'] && !store.getters['installments/isEmpty'])

    const canChangeLang = computed(() => {
      return IS_WEB && locale.SUPPORTED_LANGUAGE.length > 1
    })

    return {
      user,
      canChangeLang,
      isShowCookie,
      isShowInstallments
    }
  }
})
