
import { defineComponent, ref } from 'vue'

import useLang from '@web/composition/useLang'
import { availableLang } from '@web/common/locale'

export default defineComponent({
  name: 'ChangeLang',
  props: {
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const showBlock = ref(false)

    function onToggleBlock () {
      showBlock.value = !showBlock.value
    }

    return {
      showBlock,
      onToggleBlock,
      availableLang,
      ...useLang()
    }
  }
})
