
import { defineComponent, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import SettingsSidebar from '@web/components.v2/Settings/Sidebar.vue'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsBannersTop from '@web/components/AsBannersTop.vue'
import AsBanner from '@web/components/AsBanner.vue'

import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import { useMeta } from '@web/common/vueMeta'
import { useSettingsBanners } from '@web/composition/useBanners'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsHeaderMain,
    AsMenu,
    AsFooter,
    AsSpacer,
    AsBreadcrumb,
    SettingsSidebar,
    AsBannersTop,
    AsBanner
  },
  setup () {
    const store = useStore()

    const prevPage = { name: PAGE_NAME.HOME }

    const title = i18n.global.t('crumb.settings')
    const menu = computed(() => store.getters['menu/general'])

    useMeta({
      title
    })

    return {
      prevPage,
      title,
      menu,
      ...useSettingsBanners()
    }
  }
})
